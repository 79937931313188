import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/create-presale",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/launched/Launchedform")),
  },

  {
    exact: true,
    path: "/pool-project",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/launched/LivePreSales")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/component/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Disclaimer")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Faq/How")),
  },
  {
    exact: true,
    path: "/terms-of-services",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/StaticContent/TermsOfServices")
    ),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/AboutUs")),
  },

  {
    exact: true,
    path: "/our-team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/TeamBehind")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Roadmap")),
  },

  {
    exact: true,
    path: "/pooldetails",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Pool/Pool")),
  },
  {
    exact: true,
    path: "/admin-controle",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Admin/AdminControl")),
  },
  // {
  //   exact: true,
  //   path: "/admin-control",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/AdminControle/index")),
  // },
  {
    exact: true,
    path: "/token-creator",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/token/Token")),
  },
  {
    exact: true,
    path: "/view-token",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/token/Tab/ViewToken")),
  },
  // {
  //   exact: true,
  //   path: "/security-bounties",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Boundaries")),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
