import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,

  overrides: {
    MuiDivider: {
      root: {
        border: " none",
        height: "1px",
        margin: 0,
        flexShrink: 0,
        backgroundColor: "#4AE69C",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
    MuiTableCell: {
      body: {
        color: "#ccc",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#898989",
      },
      barColorPrimary: {
        backgroundColor: "#EAB73B",
      },
    },
    MuiStepLabel: {
      label: {
        "& .MuiStepLabel": {
          "&:active": {
            color: "#fff !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(225 211 211 / 23%)",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },

    MuiPaper: {
      root: {
        border: "1px solid #6D6D6D",
        // backgroundColor: "#6D6D6D",
        border: "1px solid #6D6D6D",

        // opacity: " 0.7",
        backgroundColor: " #212121",
        boxSizing: " border-box",
        // borderRadius: " 20px",
        backdropFilter: "blur(4px)",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },

    MuiListItem: {
      root: {
        alignItems: "self-start",
        paddingTop: "4px",
        paddingBottom: "4px",
        fontSize: "15px",
        fontFamily: "Roboto",
        color: "rgb(150, 149, 149)",
        "&:hover": {
          color: "#EAB73B",
        },
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        backgroundColor: "rgb(27 24 24 / 92%)",
        Width: 450,
        maxWidth: "100%",
        border: "1px solid #6D6D6D",
        "&:hover": {
          border: "1px solid #EAB73B",
        },
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#fff",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        color: "#fff",
        "&.Mui-disabled": {
          color: "#6d6d6d !important",
        },
      },
      outlined: {
        "&.Mui-disabled": {
          border: "1px solid #4d4a4a !important",
        },
      },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        backgroundColor: "#FC8240",
        padding: "5px 19px",
      },

      containedPrimary: {
        backgroundColor: "transparent",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "15px",
        height: "40px",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "10px",
        "&:hover": {
          backgroundColor: "#EAB73B",
          color: "#000",
        },
      },
      containedSecondary: {
        backgroundColor: "#EAB73B",
        color: "#ffffff",
      },
      // outlined: {
      //   border: "2px solid #48c58a",
      // },
      contained: {
        borderRadius: "50px",
        border: "1px solid #EAB73B",
        backgroundColor: "#EAB73B",
        fontWeight: 600,
        color: "#000",
        padding: "5px 19px",

        "&:hover": {
          backgroundColor: "transparent",
          border: "1px solid #EAB73B",
          color: "#fff",
        },
        // "&.Mui-disabled": {
        //   color: "rgb(222 217 217 / 28%)",
        // },
      },
      outlinedPrimary: {
        borderRadius: "50px",

        color: "#fff",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #EAB73B",
        "&:hover": {
          backgroundColor: "#EAB73B",
          border: "2px solid #EAB73B",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      list: {
        padding: "10px",
      },
      paper: { top: "47px" },
    },

    MuiStepper: {
      horizontal: {
        "@media (max-width: 767px)": {
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
        },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
        boxSizing: "border-box",
        backdropFilter: "blur(4px)",
        background: "#212121",
        borderRadius: "24px",
      },
    },
    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
      body1: {
        fontSize: "20px",
        fontFamily: "Poppins",
        fontWeight: "400",
        lineHeight: "1.5",
      },
    },

    MuiStepIcon: {
      text: {
        fill: "#090b16",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#fff",
        },
        "&.MuiStepIcon-completed": {
          color: "#388c64",
        },
        "&.MuiStepIcon-active": {
          color: "#EAB73B",
        },
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: " 1px solid #6D6D6D",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
