import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  Divider,
  ListItem,
  makeStyles,
  // ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";

// import { AiFillMediumCircle } from "react-icons/ai";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { BsDiscord } from "react-icons/bs";
// import { RiDiscordLine } from "react-icons/ri";
// import { TiSocialYoutubeCircular } from "react-icons/ti";
// import { RiTelegramLine } from "react-icons/ri";
// import { FaInstagram } from "react-icons/fa";
// import { FaFacebook } from "react-icons/fa";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    // backgroundImage: "url(./images/footerbg.png)",
    // borderBottom: "1px solid #423F3F",
    background: "#131313",
    borderTop: "1px solid #423F3F",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
    },
    "& ul": {
      padding: "0",
    },
    "& h6": {
      fontWeight: "400",
      color: "#ffffff",
      // marginTop: "20px",
      // marginBottom: "20px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },

  footerTitle: {
    "&:hover": {
      color: " #FF632C",
    },
  },

  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  button: {
    backgroundColor: "#fab758",
    color: "#ffffff",
    border: "2px solid #fab758",
    borderRadius: "40px",
    minHeight: "50px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: "500",
    transition: "0.26s ease",
    minWidth: "160px",
    paddingBottom: "3px",
    textTransform: "capitalize",
    marginRight: "15px",
    marginBottom: "10px",
    marginTop: "27px",
  },
  join: {
    color: "#ffffff",
    fontSize: "30px",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#3F78E0",
      minWidth: "90px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
  copyrightText: {
    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        padding: "0px",
      },
    },
  },
  footerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "15px 0px",

    "& .innerBox": {
      padding: "0px 15px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 10px",
      },
      "& a": {
        padding: "0px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "11px",
          padding: "0px",
        },
      },
      "& ListItem": {
        "&:hover": {
          color: "#000000",
        },
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  var year3 = new Date().getFullYear();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="md">
          <Grid container spacing={3} direction={"column"}>
            <Grid item xs={12} align="center">
              <Box my={2}>
                <img
                  src="/images/logo.png"
                  alt=""
                  width="100%"
                  style={{ maxWidth: "250px", margin: "0 auto" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Grid container spacing={1} textAlign="center">
                  <Grid item lg={3} sm={3} md={3} xs={6}>
                    <Typography className={classes.footerTitle} variant="h6">
                      ABOUT
                    </Typography>
                    <List className="listingFooter">
                      <ListItem
                        style={{ cursor: "pointer" }}
                        to="/about-us"
                        component={Link}
                      >
                        About us
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        to="/create-presale"
                        component={Link}
                      >
                        Create Launchpad
                      </ListItem>

                      {/* <ListItem
                        style={{ cursor: "pointer" }}
                        to="/our-team"
                        component={Link}
                      >
                        Our Team
                      </ListItem> */}
                      <ListItem
                        style={{ cursor: "pointer" }}
                        to="/terms-of-services"
                        component={Link}
                      >
                        Terms of service
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        to="/privacy-policy"
                        component={Link}
                      >
                        Privacy policy
                      </ListItem>
                    </List>{" "}
                  </Grid>

                  <Grid item lg={3} sm={3} md={3} xs={6}>
                    <Typography className={classes.footerTitle} variant="h6">
                      SUPPORT
                    </Typography>
                    <List className="listingFooter">
                      <ListItem
                        to="/faq"
                        component={Link}
                        style={{ cursor: "pointer" }}
                      >
                        FAQ
                      </ListItem>
                    </List>{" "}
                  </Grid>
                  <Grid item lg={3} sm={3} md={3} xs={6}>
                    <Typography className={classes.footerTitle} variant="h6">
                      SOCIAL
                    </Typography>
                    {/* <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    > */}{" "}
                    <List className="listingFooter">
                      <ListItem
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={() => window.open("https://twitter.com/")}
                      >
                        {" "}
                        {/* <TwitterIcon /> */}
                        Twitter
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={() => window.open("https://web.telegram.org/")}
                      >
                        {" "}
                        {/* <TelegramIcon /> */}
                        Telegram
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={() => window.open("https://www.youtube.com/")}
                      >
                        {" "}
                        {/* <YouTubeIcon /> */}
                        YouTube
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={() =>
                          window.open("https://www.instagram.com/")
                        }
                      >
                        {" "}
                        {/* <InstagramIcon /> */}
                        Instagram
                      </ListItem>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={() =>
                          window.open("https://www.facebook.com/ ")
                        }
                      >
                        {" "}
                        {/* <FacebookIcon /> */}
                        Facebook
                      </ListItem>
                    </List>
                    {/* </Box> */}
                  </Grid>
                  <Grid item lg={3} sm={3} md={3} xs={6}>
                    <Typography className={classes.footerTitle} variant="h6">
                      CONTACT US
                    </Typography>
                    <a
                      href="mailto:info@ebullsPad.io"
                      style={{ color: "#969595", textDecoration: "none" }}
                    >
                      ebullsPad.io
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Divider style={{ backgroundColor: "#423F3F" }} />
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={7} md={8} align="left">
              <Box className={classes.footerContent}>
                <Box className="innerBox">
                  <ListItem
                    style={{ color: "#ffffff" }}
                    to="/terms-of-services"
                    component={Link}
                  >
                    TERMS OF SERVICE
                  </ListItem>
                </Box>

                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ backgroundColor: "#423F3F" }}
                />
                <Box className="innerBox">
                  <ListItem
                    style={{ color: "#ffffff" }}
                    to="/privacy-policy"
                    component={Link}
                  >
                    PRIVACY POLICY
                  </ListItem>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ backgroundColor: "#423F3F" }}
                />
                <Box className="innerBox">
                  <ListItem
                    style={{ color: "#ffffff" }}
                    to="/disclaimer"
                    component={Link}
                  >
                    DISCLAIMER
                  </ListItem>
                </Box>
                {/* <Divider orientation="vertical" flexItem />
                                <Box className="innerBox">
                                    <ListItem
                                        style={{ color: "#ffffff" }}
                                        to="/our-team"
                                        component={Link}
                                    >
                                        OUR TEAM
                                    </ListItem>
                                </Box> */}
              </Box>
            </Grid>
            <Grid
              item
              sm={5}
              md={4}
              align="right"
              className={classes.copyrightText}
            >
              <p
                style={{
                  color: "#FFF",
                  fontFamily: "Roboto",
                }}
              >
                {" "}
                {/* {`Copyright © ${year3} . All rights reserved.`} */}
                {`© copyright ${year3} by ebullsPad.io`}
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
