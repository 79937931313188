import React, { createContext, useEffect, useState } from "react";
import { injected } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  default_RPC_URL,
  factoryContractAdress,
  NetworkDetails,
  AdminOwner,
} from "../constants/";

import { toast } from "react-toastify";
import Web3 from "web3";
import { getContract, getWeb3ContractObject, getWeb3Obj } from "src/utils";
import KingShibaIDOFactoryABI from "src/abis/KingShibaIDOFactoryABI.json";
import KingShibaIDOInfoABI from "src/abis/KingShibaIDOInfoABI.json";
import KingShibaIDOPresaleABI from "src/abis/KingShibaIDOPresaleABI.json";
import moment from "moment";
import axios from "axios";
import apiConfig, { socketURL } from "src/ApiConfg/ApiConfig";

import multicall from "src/utils/multicall";
import { isAddress } from "ethers/lib/utils";

export const AuthContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [errorMsg] = useState("");
  const [userData, setUserData] = useState();
  const [connectwalletBalance, setConnectwalletBalance] = useState(0);
  const [numberOfPools, setNumberOfPools] = useState(0);
  const [poolList, setPoolList] = useState([]);
  const [userPoolList, setUserPoolList] = useState([]);
  const [livePoolList, setLivePoolList] = useState([]);
  const [closePoolList, setClosePoolList] = useState([]);
  const [premiumLists, setPremiumLists] = useState([]); // premiumLists,nonPremiumLists,fairLists

  const [nonPremiumLists, setNonPremiumLists] = useState([]);
  const [fairLists, setFairLists] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");

  const [FinishedStakingPools, setFinishedStakingPools] = useState([]);

  const [usdPrice, setUsdPrice] = useState(0);
  const [poolListAPI, setPoolListAPI] = useState([]);
  const [searchToken, setSearchToken] = useState("");
  const [searchToken1, setSearchToken1] = useState("");
  const [view, setView] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [poolLoader, setpoolLoader] = useState(false);
  const [search1, setSearch1] = useState("");
  const [saleEndFair, setsaleEndFair] = React.useState([]);
  const [saleLiveFair, setsaleLiveFair] = React.useState([]);
  const [cancledFairFair, setcancledFairFair] = React.useState([]);
  const [upcomingFair, setupcomingFair] = React.useState([]);
  const [filledEndedFair, setfilledEndedFair] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [saleEndFairPre, setsaleEndFairPre] = React.useState([]);
  const [saleLiveFairPre, setsaleLiveFairPre] = React.useState([]);
  const [upcomingFairPre, setupcomingFairPre] = React.useState([]);
  const [filledEndedFairPre, setfilledEndedFairPre] = React.useState([]);
  const [cancledFairPre, setcancledFairPre] = React.useState([]);
  const [feesList, setFeesList] = React.useState([]);

  const [roundData, setroundData] = useState([]);

  const token = sessionStorage.getItem("token");

  const ListFees = async (searchKey) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listFees,
      });
      if (res.data.statusCode === 200) {
        setFeesList(res.data.result[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentUSDPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd"
      );
      if (res.status === 200) {
        // console.log("res", res.data.wbnb.usd);
        setUsdPrice(res.data.wbnb.usd);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    getCurrentUSDPrice();
  }, []);

  useEffect(() => {
    if (account) {
      connectWalletAPICall();
      setWalletAddress(account);
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);
  // console.log("account", account);

  const disconnectWallte = async () => {
    deactivate();
  };

  const connectWalletAPICall = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.connectWallet,

        data: {
          walletAddress: account,
        },
      });
      if (res.data.statusCode === 200) {
        setTokenSession(res.data.result.token);
        setUserData(res.data.result);
        // console.log("account", account);
        setIsLogin(true);
        setIsLoading(false);
      } else {
        deactivate();

        toast.error(res.data.responseMessage);
        setTokenSession();
      }
    } catch (error) {
      setTokenSession();
      deactivate();

      setIsLoading(false);
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };

  //NETWORK CHECK AND SWICH NETWORK

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setConnectwalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  const poolListAPIHandler = async (search11) => {
    try {
      let SearchT =
        search11 == "" ? false : search11 == undefined ? false : true;
      if (SearchT) {
        // console.log(SearchT);
        let search;
        if (search11 != "") {
          search = search11;
        } else {
          search = [];
        }
        setPoolList([]);
        setpoolLoader(true);
        const res = await axios.get(apiConfig.presaleList, {
          params: { search },
        });
        if (res.data.statusCode === 200) {
          const list = res.data.result;
          // console.log("lists----------", list);
          // console.log(list);
          setPoolListAPI(list);
          const web3 = await getWeb3Obj(default_RPC_URL);
          let array = [];
          const add = await Promise.all(
            list.map(async (listData) => {
              const desA = isAddress(listData.presaleAddress);
              // console.log("239 ==>", desA);
              if (desA === true) {
                if (listData.presaleAddress) {
                  const calls = [
                    {
                      address: listData.presaleAddress,
                      name: "totalTokens",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "totalInvestorsCount",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "totalCollectedWei",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "tokensLeft",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "investmentToken",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "presaleCreatorAddress",
                    },

                    {
                      address: listData.presaleAddress,
                      name: "presaleCancelled",
                    },
                  ];

                  const [
                    totalTokens,
                    totalInvestorsCount,
                    totalCollectedWei,
                    tokensLeft,
                    investmentToken,
                    presaleCreatorAddress,
                    presaleCancelled,
                  ] = await multicall(KingShibaIDOPresaleABI, calls);

                  let obj = {
                    ...listData,
                    presaleCreatorAddress: presaleCreatorAddress,
                    presaleCancelled: presaleCancelled[0],
                    investmentToken: investmentToken,
                    contractAddress: listData.presaleAddress,
                    presaleAddress: listData.presaleAddress,
                    saleTitle: listData.title,
                    openTime: listData.startTime,
                    closeTime: listData.endTime,
                    totalTokens: web3.utils.fromWei(
                      totalTokens.toString(),
                      "ether"
                    ),
                    ABCDtotalInvestorsCount: totalInvestorsCount.toString(),

                    totalCollectedWei: web3.utils.fromWei(
                      totalCollectedWei.toString(),
                      "ether"
                    ),
                    tokensLeft: web3.utils.fromWei(
                      tokensLeft.toString(),
                      "ether"
                    ),
                  };
                  array.push(obj);
                  // console.log("array data---------- obj", obj);
                  // return obj;
                }
              }
            })
          );
          // console.log("-----------------------------array", array);
          // console.log("-----------------------------add", add);
          setPoolList(array);

          setpoolLoader(false);
          // setSearch1("");
        }
      } else {
        let search;
        if (search11 != "") {
          search = search11;
        } else {
          search = [];
        }
        setPoolList([]);
        setpoolLoader(true);
        const res = await axios.get(apiConfig.presaleList, {});
        if (res.data.statusCode === 200) {
          const list = res.data.result;
          // console.log("lists----------", list);
          // console.log(list);
          setPoolListAPI(list);
          const web3 = await getWeb3Obj(default_RPC_URL);
          let array = [];
          const add = await Promise.all(
            list.map(async (listData) => {
              const desA = isAddress(listData.presaleAddress);
              // console.log("239 ==>", desA);

              const multi = await getWeb3ContractObject(
                KingShibaIDOPresaleABI,
                listData.presaleAddress,
                default_RPC_URL
              );
              // console.log("contract----------", multi);
              if (
                listData.presaleAddress !==
                "0xE8C852FB61a6350caa4a5301ECaEa4F5DF2eAdE9"
              ) {
                if (desA === true) {
                  if (listData.presaleAddress) {
                    const calls = [
                      {
                        address: listData.presaleAddress,
                        name: "totalTokens",
                      },

                      {
                        address: listData.presaleAddress,
                        name: "totalInvestorsCount",
                      },
                      {
                        address: listData.presaleAddress,
                        name: "totalCollectedWei",
                      },
                      {
                        address: listData.presaleAddress,
                        name: "tokensLeft",
                      },
                      {
                        address: listData.presaleAddress,
                        name: "investmentToken",
                      },
                      {
                        address: listData.presaleAddress,
                        name: "presaleCreatorAddress",
                      },
                      {
                        address: listData.presaleAddress,
                        name: "presaleCancelled",
                      },
                    ];

                    const [
                      totalTokens,
                      totalInvestorsCount,
                      totalCollectedWei,
                      tokensLeft,
                      investmentToken,
                      presaleCreatorAddress,
                      presaleCancelled,
                    ] = await multicall(KingShibaIDOPresaleABI, calls);

                    let obj = {
                      ...listData,
                      presaleCreatorAddress: presaleCreatorAddress,
                      presaleCancelled: presaleCancelled[0],
                      investmentToken: investmentToken,
                      contractAddress: listData.presaleAddress,
                      presaleAddress: listData.presaleAddress,
                      saleTitle: listData.title,
                      openTime: listData.startTime,
                      closeTime: listData.endTime,
                      totalTokens: web3.utils.fromWei(
                        totalTokens.toString(),
                        "ether"
                      ),
                      ABCDtotalInvestorsCount: totalInvestorsCount.toString(),
                      totalCollectedWei: web3.utils.fromWei(
                        totalCollectedWei.toString(),
                        "ether"
                      ),
                      tokensLeft: web3.utils.fromWei(
                        tokensLeft.toString(),
                        "ether"
                      ),
                    };
                    array.push(obj);
                    // return obj;
                  }
                }
              }
            })
          );
          // console.log("-----------------------------array", array);
          // console.log("-----------------------------add", add);
          setPoolList(array);

          setpoolLoader(false);
          // setSearch1("");
        }
      }
    } catch (error) {
      // setSearch1("");
      setpoolLoader(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    // const cancelTokenSource = axios.CancelToken.source();

    poolListAPIHandler();
    // return () => {
    //   cancelTokenSource.cancel();
    // };
  }, []);

  const poolListingData = async () => {
    const web3 = await getWeb3Obj(default_RPC_URL);
    // setPoolList([]);
    try {
      const contract = new web3.eth.Contract(
        KingShibaIDOFactoryABI,
        factoryContractAdress
      );
      const poolDataListing = await contract.methods.TESTIDO().call();
      const poolListingObj = new web3.eth.Contract(
        KingShibaIDOInfoABI,
        poolDataListing
      );
      const presalesCount = await poolListingObj.methods
        .getPresalesCount()
        .call();

      setNumberOfPools(presalesCount);
      for (var i = presalesCount - 1; i >= 0; i--) {
        var presaleAddress = await poolListingObj.methods
          .getPresaleAddress(i)
          .call();

        const calls = [
          {
            address: presaleAddress,
            name: "totalTokens",
          },
          {
            address: presaleAddress,
            name: "closeTime",
          },
          {
            address: presaleAddress,
            name: "saleTitle",
          },
          {
            address: presaleAddress,
            name: "openTime",
          },
          {
            address: presaleAddress,
            name: "totalCollectedWei",
          },
          {
            address: presaleAddress,
            name: "tokensLeft",
          },
          {
            address: presaleAddress,
            name: "investmentToken",
          },
          {
            address: presaleAddress,
            name: "presaleCreatorAddress",
          },
        ];

        const [
          totalTokens,
          closeTime,
          saleTitle,
          openTime,
          totalCollectedWei,
          tokensLeft,
          investmentToken,
          presaleCreatorAddress,
          // quoteTokenDecimals
        ] = await multicall(KingShibaIDOPresaleABI, calls);

        let obj = {
          presaleCreatorAddress: presaleCreatorAddress,
          investmentToken: investmentToken,
          contractAddress: presaleAddress,
          presaleAddress: presaleAddress,
          saleTitle: web3.utils.hexToAscii(saleTitle[0]),
          openTime: openTime,
          closeTime: closeTime,
          totalTokens: web3.utils.fromWei(totalTokens.toString(), "ether"),
          totalCollectedWei: web3.utils.fromWei(
            totalCollectedWei.toString(),
            "ether"
          ),
          tokensLeft: web3.utils.fromWei(tokensLeft.toString(), "ether"),
        };
        setPoolList((prev) => [...prev, obj]);

        setpoolLoader(false);
        // console.log("------++++++++++++++==========obj premimum 123", obj);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  // USER POOLS
  useEffect(() => {
    if (account) {
      const list = poolList.filter(
        (data) => data.presaleCreatorAddress == account
      );
      setUserPoolList(list);
    }
  }, [poolList, account]);

  // Live POOLS
  useEffect(() => {
    const list = poolList.filter(
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const closedList = poolList.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );

    const premiumList1 = poolList.filter((data) => data.premium === true);
    const NonPremiumList = poolList.filter((data) => data.premium === false);
    const fairList = poolList.filter((data) => data.premium === false);
    setLivePoolList(list);
    setClosePoolList(closedList);
    setPremiumLists(premiumList1);
    setNonPremiumLists(NonPremiumList);
    setFairLists(fairList);
    console.log("fairList", fairList);
    /* **************** Fair * List **************** */
    const saleEnds = fairList.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );
    const saleLive = fairList.filter(
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const upcomings = fairList.filter(
      (data) => Number(data.openTime) > moment().unix()
    );
    // console.log("saleEnd   ----saleEnds----------", saleEnds);

    // console.log(saleEnds, "saleEnds------------search1");
    /* **************** Fair * List **************** */
    setupcomingFair(upcomings);
    setsaleEndFair(saleEnds);
    setsaleLiveFair(saleLive);

    /* **************** Fair *Filled* List **************** */
    const filledListFair = fairList.filter(
      (data) =>
        Math.ceil(
          ((parseFloat(data?.totalTokens) - parseFloat(data?.tokensLeft)) /
            data?.totalTokens) *
            100
        ) == 100
    );
    // console.log("------ Fair *Filled* List ---------------", filledListFair);
    /* **************** Fair *Filled* List **************** */
    setfilledEndedFair(filledListFair);
    /* **************** Fair *Filled* List **************** */
    /* **************** Fair * List **************** */

    /* **************** ------pre * List------ **************** */
    const saleEndsPre = premiumList1.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );
    const saleLivePre = premiumList1.filter(
      // (data) => Number(data.closeTime) < moment().unix()
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const cancledPreFair = premiumList1.filter(
      (data) => data.presaleCancelled == true
    );
    const upcomingsPre = premiumList1.filter(
      (data) => Number(data.openTime) > moment().unix()
    );
    // console.log("saleEndsPre------------search1", saleEndsPre);
    /* **************** Fair * List **************** */
    setupcomingFairPre(upcomingsPre);
    setsaleEndFairPre(saleEndsPre);
    setsaleLiveFairPre(saleLivePre);
    setcancledFairPre(cancledPreFair);
    /* **************** Pre *Filled* List **************** */
    const filledListFairPre = premiumList1.filter(
      (data) =>
        Math.ceil(
          ((parseFloat(data?.totalTokens) - parseFloat(data?.tokensLeft)) /
            data?.totalTokens) *
            100
        ) == 100
    );
    // console.log("------ pre *Filled* List ---------------", filledListFairPre);
    /* **************** pre *Filled* List **************** */
    setfilledEndedFairPre(filledListFairPre);
    /* **************** ptr *Filled* List **************** */
  }, [poolList, account]);

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const currentBlockNumber = web3.eth.getBlock("latest");

  useEffect(() => {
    if (searchToken !== "") {
    }
  }, [searchToken]);
  //bsc--------------------------------------

  const [searchTokenDetails, setSearchTokenDetails] = useState([]);
  const [isLoadingSearchedData, setIsLoadingSearchedData] = useState(0);
  const [isSearchTrue, setIsSearchTrue] = useState(false);
  const [bscScanResult, setBscScanResult] = useState("");

  const [networkWalletData, setNetworkWalletData] = useState([]);

  const networkWalletAPIHandler = async (netowrk) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listWallet,
        headers: {
          token: token,
        },
        params: {
          contractType: netowrk,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkWalletData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(account);
  let data = {
    isLogin,
    saleLiveFair,
    saleEndFair,
    cancledFairFair,
    upcomingFair,
    filledEndedFair,

    saleLiveFairPre,
    saleEndFairPre,
    upcomingFairPre,
    filledEndedFairPre,
    cancledFairPre,

    walletAddress,
    errorMsg,
    userData,
    searchToken,
    numberOfPools,
    poolList,
    connectwalletBalance,
    userPoolList,
    livePoolList,

    FinishedStakingPools,

    usdPrice,
    poolListAPI,
    premiumLists,
    nonPremiumLists,
    fairLists,
    searchTokenDetails,

    poolLoader,

    isLoadingSearchedData,
    isSearchTrue,

    roundData,

    feesList,

    networkWalletData,

    closePoolList,

    setView,
    setSearch1,
    search1,
    viewLoader,
    setIsSearchTrue: (data) => setIsSearchTrue(data),
    setSearchToken: (data) => setSearchToken(data),
    setSearchToken1: (data) => setSearchToken1(data),
    setIsLoadingSearchedData: (data) => setIsLoadingSearchedData(data),

    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    poolListingData: (search11) => poolListAPIHandler(search11),

    ListFeesFun: () => ListFees(),
    dicconectWalletFun: () => {
      disconnectWallte();
      setIsLoading(false);
    },
    logoutHanlder: () => {
      setUserData();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
