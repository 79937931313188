import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { BsFillCaretDownFill } from "react-icons/bs";
import { withStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useWeb3React } from "@web3-react/core";
import { AuthContext } from "src/context/Auth";

import { toast } from "react-toastify";
// import ModalSecondary from "src/views/pages/ProjectDetails/ModalSecondary";
import { getContract, getWeb3Obj, sortAddress } from "src/utils";
import FactoryABI from "src/abis/KingShibaIDOFactoryABI.json";
import { factoryContractAdress } from "src/constants";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { SignalCellularNoSimOutlined } from "@material-ui/icons";

const headersData = [
  // {
  //   label: "Admin Controle",
  //   href: "/admin-controle",
  // },
  {
    label: "Pool",
    href: "/pool-project",
  },
  {
    label: "About Us",
    href: "/about-us",
  },
  {
    label: "Create Pool",
    href: "/create-presale",
  },
  {
    label: "Token Creator",
    href: "/token-creator",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 6px",
    letterSpacing: "1px",
    marginLeft: "0px",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#fff",
    },
    "&:hover": {
      color: "#EAB73B",
    },
  },
  menuButton1: {
    width: "100%",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    // "@media(max-width:540px)": {
    //   alignItems: "center",
    // },
    // "@media(max-width:280px)": {
    //   alignItems: "flex-start",
    // }
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #EAB73B",
    padding: "0 15px",
    background: "#EAB73B",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#EAB73B",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  menuMobile1: {
    backgroundColor: " #eab73b",
    padding: "12px",
    borderRadius: "40px",
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: "#000",
      margin: "0 5px",
      // "@media (max-width:767px)": {
      //   display: "none",
      // },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: " #FCF2FA",
      borderRadius: "40px",
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { account, chainId, library } = useWeb3React();
  console.log("account++++", account);
  const auth = useContext(AuthContext);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    menuMobile1,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
    divstake,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const [addToWhitelist, setAddToWhiteList] = useState("");
  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [updateMinSatkeOpen1, setUpdateMinSatkeOpen1] = useState(false);
  const [whitelistBtnName, setWhiteListBtnName] = useState("Add");
  const [isWhitelisting, setIsWhiteListing] = useState(false);
  const [listingAddresses, setListingAddress] = useState([]);
  const [isLoadingAddress, setIsloadingAddress] = useState(false);
  const [openPremium, setOpenPremium] = useState(false);
  const [isRemoving, setIsremoving] = useState(false);
  const [premiumTime, setPremiumTime] = useState("");
  const [isUpdatingPremium, setIsPremiumUpdating] = useState(false);
  const [showPreiumTime, setPremiumTimeShow] = useState();
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  console.log("auth", auth?.userData?.userType);
  const addToWhitelistHandler = async () => {
    if (account) {
      try {
        setIsWhiteListing(true);
        setWhiteListBtnName("Adding...");
        const web3 = await getWeb3Obj();
        const contractObj = getContract(
          factoryContractAdress,
          FactoryABI,
          library,
          account
        );
        const addList = addToWhitelist.split(",");
        for (var i = 0; i < addList.length; i++) {
          const dataRes = web3.utils.isAddress(addList[i]);
          if (!dataRes) {
            break;
          }
        }
        const addToWhiteListFun = await contractObj.whiteList(addList);
        await addToWhiteListFun.wait();
        toast.success("The addresses has been whitelisted successfully");
        setIsWhiteListing(false);
        setWhiteListBtnName("Add");
        setUpdateMinSatkeOpen(false);
        getNomineeDatahandler();
      } catch (err) {
        console.log(err);
        setIsWhiteListing(false);
        setWhiteListBtnName("Add");
      }
    } else {
      toast.warn("Please connect your wallet first");
      setWhiteListBtnName("Add");
      setIsWhiteListing(false);
    }
  };

  const getNomineeDatahandler = async () => {
    try {
      setListingAddress([]);
      setIsloadingAddress(true);
      const contractObj = getContract(
        factoryContractAdress,
        FactoryABI,
        library,
        account
      );
      const nomineeCount = await contractObj.getNomineeCount();
      const intNominee = parseInt(nomineeCount.toString());
      const getPremiumTime = await contractObj.premiumTime();
      const timePremium = moment
        .utc(getPremiumTime.toString() * 1000)
        .format("HH");
      setPremiumTimeShow(timePremium);
      let arr = [];
      for (var i = 0; i < intNominee; i++) {
        const nomineeList = await contractObj.nomineeList(i);
        let obj = {
          address: nomineeList,
        };
        arr.push(obj);
      }
      setListingAddress(arr);
      setIsloadingAddress(false);
    } catch (err) {
      console.log(err);
      setIsloadingAddress(false);
    }
  };
  useEffect(() => {
    if (account) {
      getNomineeDatahandler();
    }
  }, [account]);

  const removeFromWhitelistHandler = async (data) => {
    setIsremoving(true);
    if (account) {
      try {
        const contractObj = getContract(
          factoryContractAdress,
          FactoryABI,
          library,
          account
        );
        const blackList = await contractObj.blackList(data);
        await blackList.wait();
        toast.success("The address has been removed successfully");
        getNomineeDatahandler();
        setIsremoving(false);
      } catch (err) {
        console.log(err);
        setIsremoving(false);
        toast.error(err.message);
      }
    } else {
      toast.warn("Please connect your wallet");
    }
  };
  const addPreimumTimeHandler = async () => {
    setIsPremiumUpdating(true);
    try {
      const contractObj = getContract(
        factoryContractAdress,
        FactoryABI,
        library,
        account
      );
      const premium = await contractObj.setPremiumTime(
        parseInt(premiumTime) * 60 * 60
      );
      await premium.wait();
      toast.success("You have successfully set the premium time");
      setIsPremiumUpdating(false);
      getNomineeDatahandler();
    } catch (error) {
      console.log(error);
      setIsPremiumUpdating(false);
    }
  };
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}
            {auth?.userData?.userType === "Admin" && menuText}

            <Box pl={1}></Box>
            {auth &&
              account &&
              auth?.adminAccount &&
              auth?.adminAccount === account && (
                <>
                  <Button
                    color="inherit"
                    className={menuButton}
                    onClick={() => {
                      setUpdateMinSatkeOpen(true);
                    }}
                  >
                    Add to whitelist
                  </Button>
                  <Button
                    color="inherit"
                    className={menuButton}
                    onClick={() => {
                      setUpdateMinSatkeOpen1(true);
                    }}
                  >
                    View whitelist
                  </Button>
                  <Button
                    color="inherit"
                    className={menuButton}
                    onClick={() => {
                      setOpenPremium(true);
                    }}
                  >
                    Premium Time
                  </Button>
                  {/* <Link to="/admin-control" style={{ textDecoration: "none" }}>
                    <Button color="inherit" className={menuButton}>
                      Admin Control
                    </Button>
                  </Link> */}
                </>
              )}
            <Box>
              {account ? (
                <>
                  <IconButton
                    aria-label="delete"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick1}
                    className={menuMobile1}
                    size="small"
                  >
                    <Typography variant="h4">
                      {sortAddress(auth?.userData?.walletAddress)}
                    </Typography>

                    <BsFillCaretDownFill
                      style={{ color: "#000", fontSize: "16px" }}
                    />
                  </IconButton>
                </>
              ) : (
                <Button
                  style={{
                    color: "#000",
                    fontSize: "13px",
                    padding: "6px 10px",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => auth.connectWallet()}
                >
                  Connect Wallet
                </Button>
              )}
              <Box className={divstake}>
                <StyledMenu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  <MenuItem style={{ paddingLeft: "5px" }}>
                    {auth?.userData?.name
                      ? auth?.userData?.name.slice(0, 5) + ".."
                      : sortAddress(auth?.userData?.walletAddress)}
                    <CopyToClipboard text={auth?.userData?.walletAddress}>
                      <FileCopyIcon
                        style={{
                          cursor: "pointer",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                        onClick={() => toast.info("Copied")}
                      />
                    </CopyToClipboard>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      auth.dicconectWalletFun();
                      setAnchorEl1();
                    }}
                    style={{ paddingLeft: "5px" }}
                  >
                    Disconnect
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Box>

            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "community")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "community")}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/footerlogo.png" alt="" />
            {getDrawerChoices()}
            {auth?.userData?.userType === "Admin" && menuText}
            {auth &&
              account &&
              auth?.adminAccount &&
              auth?.adminAccount === account && (
                <>
                  <Button
                    color="inherit"
                    className={menuMobile}
                    onClick={() => {
                      setUpdateMinSatkeOpen(true);
                    }}
                  >
                    Add to whitelist
                  </Button>
                  <Button
                    color="inherit"
                    className={menuMobile}
                    onClick={() => {
                      setUpdateMinSatkeOpen1(true);
                    }}
                  >
                    View whitelist
                  </Button>
                  <Button
                    color="inherit"
                    className={menuMobile}
                    onClick={() => {
                      setOpenPremium(true);
                    }}
                  >
                    Premium Time
                  </Button>
                  {/* <Button
                    color="inherit"
                    className={menuMobile}
                    onClick={() => {
                      setOpenPremium(true);
                    }}
                  >
                    Admin Control
                  </Button> */}
                </>
              )}
            {account ? (
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick1}
                className={menuMobile1}
                size="small"
              >
                <Typography variant="h4">
                  {sortAddress(auth?.userData?.walletAddress)}
                </Typography>

                <BsFillCaretDownFill
                  style={{ color: "#000", fontSize: "16px" }}
                />
              </IconButton>
            ) : (
              <Button
                style={{
                  color: "#000",
                  fontSize: "13px",
                  padding: "6px 10px",
                }}
                variant="contained"
                color="secondary"
                onClick={() => auth.connectWallet()}
              >
                Connect Wallet
              </Button>
            )}
            <Box className={divstake}>
              <StyledMenu
                id="simple-menu"
                disableScrollLock={true}
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose4}
              >
                <MenuItem style={{ paddingLeft: "5px" }}>
                  {auth?.userData?.name
                    ? auth?.userData?.name.slice(0, 5) + ".."
                    : sortAddress(auth?.userData?.walletAddress)}
                  <CopyToClipboard text={auth?.userData?.walletAddress}>
                    <FileCopyIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "15px",
                        marginLeft: "5px",
                      }}
                      onClick={() => toast.info("Copied")}
                    />
                  </CopyToClipboard>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    auth.dicconectWalletFun();
                    setAnchorEl1();
                  }}
                  style={{ paddingLeft: "5px" }}
                >
                  Disconnect
                </MenuItem>
              </StyledMenu>
            </Box>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#EAB73B", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  const menuText = (
    <nav>
      <Box>
        <Box>
          <Button
            className={menuButton}
            onClick={() => history.push("/admin-controle")}
          >
            Admin Controle
          </Button>
        </Box>
      </Box>
    </nav>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
      {updateMinSatkeOpen && (
        <Dialog
          open={updateMinSatkeOpen}
          onClose={() => {
            if (!isWhitelisting) {
              setUpdateMinSatkeOpen(false);
            }
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent className="modalBg">
            <Box
              style={{
                background: "#212121",
                color: "#fff",
                padding: "12px",
                borderRadius: "12px",
              }}
            >
              <Typography variant="h6" style={{ color: "#fff" }}>
                Add to whitelist
              </Typography>
            </Box>
            <Box py={3}>
              <TextField
                id="outlined-basic"
                placeholder="Please enter address in comma seperated"
                fullWidth
                type="text"
                name="data0"
                inputProps={{}}
                value={addToWhitelist}
                onChange={(e) => setAddToWhiteList(e.target.value)}
              />
            </Box>
            <DialogActions>
              <Button
                onClick={() => {
                  setUpdateMinSatkeOpen(false);
                }}
                color="primary"
                disabled={isWhitelisting}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                autoFocus
                onClick={() => addToWhitelistHandler()}
                disabled={isRemoving || isWhitelisting}
              >
                {whitelistBtnName}
                {isWhitelisting && <ButtonCircularProgress />}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        open={updateMinSatkeOpen1}
        onClose={() => {
          if (!isRemoving) {
            setUpdateMinSatkeOpen1(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        // disableBackdropClick={isUpdateMinSatke}
        // disableEscapeKeyDown={isUpdateMinSatke}
      >
        <DialogContent>
          <Box
            style={{
              background: "#212121",
              color: "#fff",
              padding: "12px",
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" style={{ color: "#fff" }}>
              Whitelisted addresses
            </Typography>
          </Box>
          {/* <ModalSecondary
            setUpdateMinSatkeOpen1={(data) => setUpdateMinSatkeOpen1(data)}
            listingAddresses={listingAddresses}
            isLoadingAddress={isLoadingAddress}
            removeFromWhitelistHandler={(data) =>
              removeFromWhitelistHandler(data)
            }
            isRemoving={isRemoving}
          /> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPremium}
        onClose={() => {
          if (!isUpdatingPremium) {
            setOpenPremium(false);
          }
        }}
        minWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            style={{
              background: "#EAB73B",
              color: "#fff",
              padding: "25px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography variant="h6" style={{ color: "#fff" }}>
              {`Premium Time (${showPreiumTime ? showPreiumTime : "0"}) Hours`}
            </Typography>
          </Box>
          <Box p={3}>
            <TextField
              placeholder="Please enter premium time"
              fullWidth
              type="number"
              value={premiumTime}
              onChange={(e) => setPremiumTime(e.target.value)}
              helperText="Please enter time in hours"
            />
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenPremium(false);
              }}
              color="primary"
              disabled={isUpdatingPremium}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={addPreimumTimeHandler}
              disabled={isUpdatingPremium}
            >
              Submit {isUpdatingPremium && <ButtonCircularProgress />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
